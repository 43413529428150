import React from "react";
import {navigate} from "gatsby";
import { v4 as uuidv4 } from 'uuid';
import createRule from "../../../../helpers/createRule";
import OfferActionItem from "../components/offerActionItem/offerActionItem";
import {updateRule} from "../../../../helpers/updateRule";
import compatiblePPOffer from '../../../../helpers/compatiblePPOffer'
const editOffer = (id, offerType, cartType, checkoutType, analytics, rule, rules) => {
  if ("Post Purchase" === offerType) {
    navigate(
      `/app/offers/postPurchaseOfferItem`,
      {
        state: {
          rule: compatiblePPOffer(rule),
          rules: rules,
        },
        replace: true,
      }
    )
  } else if ("Product" === offerType) {
    navigate(
      `/app/offers/productOfferItem`,
      {
        state: {
          rule: rule,
          rules: rules,
        },
        replace: true,
      }
    )
  } else {
    if ("Cart" === offerType) {
      if ("drawer" === cartType) {
        navigate(
          `/app/offers/cartDrawerOfferItem`,
          {
            state: {
              rule: rule,
              rules: rules,
            },
            replace: true,
          }
        )
      } else {
        navigate(
          `/app/offers/cartOfferItem`,
          {
            state: {
              rule: rule,
              rules: rules,
            },
            replace: true,
          }
        )
      }
    } else {
      if ("extension" === checkoutType) {
        navigate(
          `/app/offers/checkoutExtensionOfferItem`,
          {
            state: {
              rule: rule,
              rules: rules,
              versionAnalytics: analytics && analytics[id] && analytics[id]['version'] || null,
            },
            replace: true,
          }
        )
      } else {
        navigate(
          `/app/offers/item`,
          {
            state: {
              rule: rule,
              rules: rules,
              versionAnalytics: analytics && analytics[id] && analytics[id]['version'] || null,
            },
            replace: true,
          }
        )
      }
    }
  }
};

const duplicateOffer = async (id, rules, token, shop, host, setToastActive) => {
  const rule = rules.find((rule) => rule.id === id)
  rule.id = uuidv4();
  rule.name = '' === rule.name ? 'Copy of {{blank}}' : `Copy of ${rule.name}`
  await createRule(rule, token, shop, host)
  setToastActive({active: true, message: 'Offer duplicated'})
};

const editOfferStatus = (rule, token, shop, firebase, setToastActive) => {
  const payload = {
    id: rule.id,
    enabled: !rule.enabled
  };

  if (rule?.archived && rule.enabled === false) {
    payload.archived = false;
  }

  updateRule(payload, token, shop, firebase);
  setToastActive({active: true, message: rule.enabled ? 'Offer disabled' : 'Offer enabled'})
};

const deleteOffer = (rule, token, shop, firebase, setToastActive) => {
  if (rule?.archived && rule.enabled === false) {
    updateRule({
        id: rule.id,
        enabled: false,
        archived: false,
      },
      token, shop, firebase);
  } else {
    updateRule({
        id: rule.id,
        enabled: false,
        archived: true,
      },
      token, shop, firebase);
  }
  setToastActive({active: true, message: rule?.archived ? 'Offer restored' : 'Offer deleted'})
};

export default (id, offerType, cartType, checkoutType, analytics, rule, rules, token, shop, host, firebase, setToastActive) => {
  return [
    {
      id: 'edit',
      content: <OfferActionItem actionType={'edit'} actionTitle={'Edit'}/>,
      onAction: () => editOffer(id, offerType, cartType, checkoutType, analytics, rule, rules),
    },
    {
      id: 'duplicate',
      content: <OfferActionItem actionType={'duplicate'} actionTitle={'Duplicate'}/>,
      onAction: () => duplicateOffer(id, rules, token, shop, host, setToastActive),
    },
    {
      id: 'disable',
      content: <OfferActionItem actionType={rule.enabled ? 'disable' : 'enable'} actionTitle={rule.enabled ? 'Disable' : 'Enable'}/>,
      onAction: () => editOfferStatus(rule, token, shop, firebase, setToastActive),
    },
    {
      id: 'delete',
      content: <OfferActionItem actionType={rule?.archived ? 'restore' : 'delete'} actionTitle={rule?.archived ? 'Restore' : 'Delete'}/>,
      onAction: () => deleteOffer(rule, token, shop, firebase, setToastActive),
    },
  ];
}
