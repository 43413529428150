import { collection, doc, getDoc, setDoc } from 'firebase/firestore';

const updateRule = async (payload, token, shop, firebase, version, versionData) => {
  payload['updatedAt'] = new Date().toISOString();
  const shopRulesRef = doc(collection(firebase.firestore, 'rules'), shop);

  try {
    const snap = await getDoc(shopRulesRef);
    const oldRules = snap.exists() ? snap.data().rules : [];

    let versionBData = {
      layout: payload.layout, banner: payload.banner, message: payload.message, title: payload.title,
      subtitle: payload.subtitle, cta: payload.cta, compareAtPrice: payload.compareAtPrice, offerBackgroundColor: payload.offerBackgroundColor ? payload.offerBackgroundColor : null,
      ctaBackgroundColor: payload.ctaBackgroundColor, ctaTextColor: payload.ctaTextColor, ctaButtonTextColor: payload.ctaButtonTextColor, ctaButtonBorderColor: payload.ctaButtonBorderColor,
      widgetBorderColor: payload.widgetBorderColor, css: payload.css, buttonBorderRadius: payload.buttonBorderRadius, widgetBorderRadius: payload.widgetBorderRadius,
      buttonBorder: payload.buttonBorder, widgetBorder: payload.widgetBorder, offerStarting: payload.offerStarting, offerEnding: payload.offerEnding,
      product: payload.product, visibility: payload.visibility ? payload.visibility : null, variantsTriggers: payload.variantsTriggers,
    };

    const newRules = oldRules.map(rule => rule.id !== payload.id ? rule : ("B" === version ? 
      { ...rule, ...{ ...payload, ...versionData, versionB: versionBData } } : 
      { ...rule, ...payload }
    ));

    await setDoc(shopRulesRef, { rules: newRules });
    console.log('successfully updated rules');
    return newRules;
  } catch (e) {
    console.log('unable to update rules:', e);
  }
};

const deleteRule = async (id, token, shop, firebase) => {
  const shopRulesRef = doc(collection(firebase.firestore, 'rules'), shop);

  try {
    const snap = await getDoc(shopRulesRef);
    const oldRules = snap.exists() ? snap.data().rules : [];

    const newRules = oldRules.filter(rule => rule.id !== id);

    await setDoc(shopRulesRef, { rules: newRules });
    console.log('successfully deleted rule');
  } catch (e) {
    console.log('unable to delete rules:', e);
  }
};

const setEnabledAllRules = async (enabled, token, shop, firebase) => {
  const shopRulesRef = doc(collection(firebase.firestore, 'rules'), shop);

  try {
    const snap = await getDoc(shopRulesRef);
    const oldRules = snap.exists() ? snap.data().rules : [];

    const newRules = oldRules.map(rule => ({
      ...rule,
      enabled: enabled
    }));

    await setDoc(shopRulesRef, { rules: newRules });
    console.log('successfully updated all rules');
  } catch (e) {
    console.log('Unable to toggle all rules:', e);
  }
};

const updateRulesPriority = async (shop, firebase, rules) => {
  const shopRulesRef = doc(collection(firebase.firestore, 'rules'), shop);

  try {
    await setDoc(shopRulesRef, { rules: rules });
    console.log('successfully updated rules order');
  } catch (e) {
    console.log('Unable to update rules order:', e);
  }
};

const migrateRules = async (payload, shop, firebase) => {
  const shopRulesRef = doc(collection(firebase.firestore, 'rules'), shop);

  try {
    const snap = await getDoc(shopRulesRef);
    const oldRules = snap.exists() ? snap.data().rules : [];
    console.log('old rules:', oldRules);

    const newRules = oldRules.concat(payload);
    console.log('new rules', newRules);

    await setDoc(shopRulesRef, { rules: newRules });
    console.log('successfully migrated rules');
  } catch (e) {
    console.log('unable to migrate rules:', e);
  }
};

export { updateRule, deleteRule, setEnabledAllRules, updateRulesPriority, migrateRules };